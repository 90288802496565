import React from 'react'
import { graphql } from 'gatsby'
import AnimationGalleryPageTpl from 'templates/portfolio/gallery-animation'

const youtubes = [
	{
		src: 'https://www.youtube.com/embed/lVXhCWgzyZI',
		projectName: 'Circular waterfront house',
		description: 'Architectural animation | Architect: Honglin Li',
	},
	{
		src: 'https://www.youtube.com/embed/L49Ju56RO08',
		projectName: 'Carter Toorak House',
		description:
			'3D animation of apartment interior | Melbourne, Auststralia | Architect: Carr',
	},
	{
		src: 'https://www.youtube.com/embed/6tQPyhdmqLg',
		projectName: 'Unreal journey to home',
		description:
			'Architectural animation | Belgium | Architect: ABSoluut Architecture',
	},
	{
		src: 'https://www.youtube.com/embed/z5VZSpF0-NI',
		projectName: 'Triplex apartment',
		description:
			'Real time realistic walktour | Prague, Czech Republic | Architects: Lenka Míková and Markéta Bromová',
	},
	{
		src: 'https://www.youtube.com/embed/k71qhEZze5Q',
		projectName: 'Triplex apartment',
		description:
			'Architectural animation | Prague, Czech Republic | Architects: Lenka Míková and Markéta Bromová',
	},
]

const ProjectsAnimationPage = ({
	data: {
		prismicProjectsGallery: {
			data: { title, bold_paragraph },
		},
	},
}) => (
	<AnimationGalleryPageTpl
		topContent={{ heading: title, paragraph: bold_paragraph }}
		youtubes={youtubes}
		tag="Animation"
	/>
)

export const query = graphql`
	query animationProjectsQuery2 {
		prismicProjectsGallery {
			_previewable
			data {
				title
				bold_paragraph {
					richText
				}
			}
		}
	}
`

export default ProjectsAnimationPage
