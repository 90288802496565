import React from 'react'
import LazyLoad from 'react-lazyload'
import { Root } from 'templates/layout'
import { DesignRow, Heading5 } from 'components/design'
import TopSection from './top'
// import InnerNav from './inner-nav'

const AnimationGalleryPageTpl = ({ topContent, youtubes }) => (
	<Root>
		<TopSection {...topContent} />
		{/*<InnerNav tag="Animation" />*/}

		{youtubes.map((video) => (
			<DesignRow
				key={video.src}
				className="my-16"
				gutterToGutter={
					<>
						<LazyLoad once height={500} offset={200}>
							<div
								style={{
									position: 'relative',
									paddingBottom: '56.25%',
									height: 0,
								}}
							>
								<iframe
									style={{
										margin: '0 auto',
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
									}}
									src={video.src}
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							</div>
						</LazyLoad>
						<div className="mt-2">
							<Heading5 className="inline">{video.projectName}.</Heading5>{' '}
							{video.description}
						</div>
					</>
				}
			/>
		))}
	</Root>
)

export default AnimationGalleryPageTpl
