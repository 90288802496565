import React from 'react'
import { PrismicText } from '@prismicio/react'

const PortfolioTop = ({ heading, paragraph }) => (
	<section className="mt-44 flex w-full flex-wrap px-screen-step lg:mx-auto lg:mt-64 lg:w-screen-step-18 lg:flex-nowrap lg:px-0">
		<h1 className="w-full lg:w-screen-step-8">{heading}</h1>
		{/* To align h1 with text by baseline, compute difference in line heights and multiply by .9 to ignore bottom parts */}
		{/* It also does spacing work on mobile, thus no need to override it*/}
		<p className="regular-text w-full pt-[calc(0.9*(3.5rem-1.625rem))] lg:ml-screen-step-2 lg:w-screen-step-8">
			<PrismicText field={paragraph.richText} />
		</p>
	</section>
)

export default PortfolioTop
